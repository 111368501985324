import Intersection from 'lodash/intersection'
import store from '@state/store'

const isQualifiedFieldAvailable = (fields, qualifiedFields) => {
  // Filter out archived fields
  const filteredValues = fields
    .filter((field) => !field.archived)
    .map((i) => i.key)

  // Check if there are any qualified fields available
  return Intersection(filteredValues, qualifiedFields).length > 0
}

/**
 * Checks if the provided change object qualifies as a required note field based on the provided criteria.
 * @param {Object} change - The change object to be checked.
 * @param {Array} noteRequiredFields - The list of required note fields.
 * @param {Object} qualifiedRequiredNoteFields - The qualified required note fields criteria.
 * @returns {boolean} - Returns true if the change qualifies as a required note field, otherwise false.
 */
export const handleCheckRequiredNoteQualified = (
  change,
  noteRequiredFields,
  qualifiedRequiredNoteFields,
  moduleName
) => {
  let vueXKeys = {
    statusId: `status/${moduleName}/status`,
  }
  // Convert noteRequiredFields to strings
  const notRequiredField = noteRequiredFields.map(String)

  // Find the intersection of noteRequiredFields and keys in change
  const equalFields = Intersection(notRequiredField, Object.keys(change))

  // Check if all equalFields meet the qualified criteria
  let isQualifiedRequiredNoteField = equalFields.every((field) => {
    // Check if the field is available in the qualified fields
    if (
      qualifiedRequiredNoteFields[field] &&
      qualifiedRequiredNoteFields[field].length
    ) {
      const isFieldAvailable =
        field in vueXKeys
          ? isQualifiedFieldAvailable(
              store.getters[vueXKeys[field]] || [],
              qualifiedRequiredNoteFields[field]
            )
          : true

      if (isFieldAvailable) {
        return qualifiedRequiredNoteFields[field].indexOf(change[field]) >= 0 // Return true if found
      }
      return true
    }
    return true
  })

  return Boolean(isQualifiedRequiredNoteField)
}
