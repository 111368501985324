<template>
  <FlotoDrawerForm
    :open="open"
    width="65%"
    @cancel="$emit('cancel')"
    @submit="handleSubmit"
  >
    <template v-slot:header>
      <slot name="title"> {{ $t('edit') }}: {{ resource.name }} </slot>
    </template>
    <FlotoContentLoader
      v-if="!formConsumerLoaded"
      loading
      style="position: absolute"
    />
    <component
      :is="isPortalLogin ? 'SupportPortalTicketForm' : 'TicketForm'"
      v-if="showForm"
      :value="formData"
      :module-name="moduleName"
      :processing="processing"
      :consider-priority-matrix="false"
      :use-form-helpter-provider="false"
      :use-template="false"
      :use-transition-model="false"
      :use-link-asset="false"
      :use-link-ci="false"
      :with-submit="false"
      :use-requester="false"
      :avoid-default-value="Boolean(formData.id)"
      :additional-custom-form-fields="additionalCustomFormFields"
      @requester-details="requesterDetails = $event"
      @change="handleChange"
      @form-consumer-mounted="formConsumerLoaded = true"
    />
    <template v-slot:actions="{ hide, submit }">
      <MButton
        variant="primary"
        class="mr-2"
        :loading="processing"
        @click="submit"
      >
        {{ $t('update') }}
      </MButton>
      <MButton variant="default" @click="hide">
        {{ $t('cancel') }}
      </MButton>
    </template>
  </FlotoDrawerForm>
</template>

<script>
import { authComputed } from '@state/modules/auth'
import scriptEvaluator from '@utils/script-evaluator'
import TicketForm from '@modules/ticket/components/ticket-form'
import SupportPortalTicketForm from '@modules/support-portal/components/support-portal-ticket-form'
import { executionTypeMap, userTypeMap, eventTypeMap } from '@data/form-rules'
import { StatusComputed } from '@state/modules/status'
import { FormComputed } from '@state/modules/form'
import { getChangedProperties } from '@utils/object-difference'
import {
  fieldValueParamName,
  ReferencedSystemFieldValue,
} from '@data/qualification-execution'
export default {
  name: 'TicketFormEdit',
  components: { TicketForm, SupportPortalTicketForm },
  inject: {
    formRulesContext: {
      default: () => ({
        isFormRuleQualified: () => {},
      }),
    },
    formScriptContext: {
      default: () => ({
        form: null, // form: () => new FormHelper(),
      }),
    },
  },
  props: {
    open: { type: Boolean, default: false },
    submitFn: { type: Function, required: true },
    unTouchedResource: {
      type: Object,
      default: undefined,
    },
    resource: {
      type: Object,
      default() {
        return {}
      },
    },
    moduleName: {
      type: String,
      default() {
        return this.$constants.REQUEST
      },
    },
  },
  data() {
    return {
      width: window.innerWidth - 300,
      formData: { ...this.resource },
      processing: false,
      requesterDetails: {},
      formConsumerLoaded: false,
      showForm: false,
    }
  },
  computed: {
    ...authComputed,
    ...StatusComputed,
    ...FormComputed,
    // stages() {
    //   if (
    //     this.moduleName === this.$constants.CHANGE &&
    //     this.resource &&
    //     this.resource.statusId
    //   ) {
    //     return [
    //       'all',
    //       ...(this.resource && this.resource.statusId
    //         ? [
    //             (
    //               this[`${this.moduleName}FindStatusFromId`](
    //                 this.resource.statusId
    //               ) || {}
    //             ).stage,
    //           ]
    //         : []),
    //     ]
    //   }
    //   return undefined
    // },
    formRulesUserTypeFilter() {
      if (!this.loggedIn) {
        return [userTypeMap['all'], userTypeMap['requester']]
      }
      if (this.isPortalLogin) {
        return [
          userTypeMap['all'],
          userTypeMap['requester'],
          userTypeMap['logged_in_user'],
        ]
      }
      return [
        userTypeMap['all'],
        userTypeMap['technician'],
        userTypeMap['logged_in_user'],
      ]
    },
    additionalCustomFormFields() {
      if (
        this.moduleName === this.$constants.CHANGE &&
        this.resource &&
        this.resource.statusId
      ) {
        const stage = (
          this[`${this.moduleName}FindStatusFromId`](this.resource.statusId) ||
          {}
        ).stage
        return this[`${this.moduleName}Fields`]({
          exclude: ['system'],
          stages: [stage],
        })
      }
      return this.formRulesContext.hasServiceCatalogFormRules
        ? this.formRulesContext.serviceCatalogFormFields
        : []
    },
  },
  mounted() {
    setTimeout(() => {
      this.showForm = true
    }, 300)
  },
  methods: {
    handleChange(data) {
      this.formData = {
        ...this.formData,
        ...data,
      }
    },
    handleRemoveExexutedScript(script) {
      this.formRulesContext.handleRemoveExecutedScript(script)
    },
    handleSubmit() {
      this.processing = true
      let updatedData = this.formData
      if (
        this.formRulesContext.isFormRuleQualified({
          executionTypes: [
            executionTypeMap['create_and_edit'],
            ...(this.formData.id
              ? [executionTypeMap['edit']]
              : [executionTypeMap['create']]),
          ],
          userTypes: this.formRulesUserTypeFilter,
          eventType: eventTypeMap['submit'],
          requesterDetails: this.requesterDetails,
        })
      ) {
        this.formRulesContext.executeFormRules(this.formData, {
          executionTypes: [
            executionTypeMap['create_and_edit'],
            ...(this.formData.id
              ? [executionTypeMap['edit']]
              : [executionTypeMap['create']]),
          ],
          userTypes: this.formRulesUserTypeFilter,
          eventType: eventTypeMap['submit'],
          requesterDetails: this.requesterDetails,
        })
        const formData = this.formRulesContext.updatedResource
        const fieldRulesState = this.formRulesContext.fieldRulesState
        const overridedFieldValue = {}
        Object.keys(fieldRulesState).map((key) => {
          const fieldKey = /^\d+$/.test(key) ? +key : key
          if (
            fieldRulesState[fieldKey] &&
            'setValue' in fieldRulesState[fieldKey]
          ) {
            let finalValue = fieldRulesState[fieldKey].setValue
            // check for system field
            if (/^\d+$/.test(key) === false) {
              if (
                typeof fieldRulesState[fieldKey].setValue === 'string' &&
                /^\d+$/.test(fieldRulesState[fieldKey].setValue)
              ) {
                if (
                  Object.keys(formData).indexOf(
                    fieldRulesState[fieldKey].setValue
                  ) >= 0
                ) {
                  finalValue = formData[fieldRulesState[fieldKey].setValue]
                } else {
                  finalValue = 0
                }
              } else {
                finalValue = fieldRulesState[fieldKey].setValue
              }
            }
            overridedFieldValue[fieldKey] =
              fieldValueParamName.indexOf(fieldRulesState[fieldKey].setValue) >=
              0
                ? ReferencedSystemFieldValue(
                    fieldRulesState[fieldKey].setValue,
                    formData,
                    this.requesterDetails
                  )
                : finalValue
          }
          if (
            fieldRulesState[fieldKey] &&
            'clearValue' in fieldRulesState[fieldKey]
          ) {
            overridedFieldValue[fieldKey] = fieldRulesState[fieldKey].clearValue
          }
        })
        if (fieldRulesState.executableCustomScripts) {
          const runCustomScripts = (
            fieldRulesState.executableCustomScripts || []
          ).filter((e) => e)
          if (runCustomScripts.length) {
            return Promise.all(
              runCustomScripts.map((script) => {
                this.handleRemoveExexutedScript(script)
                return scriptEvaluator(script, {
                  MS: this.formScriptContext.form,
                  FORMDATA: this.formData,
                })
              })
            ).then(() => {
              if (
                this.formScriptContext.form &&
                !this.formScriptContext.form.canSubmit()
              ) {
                this.$errorNotification({
                  duration: 5,
                  message: 'Error',
                  description:
                    this.formScriptContext.form.getAbortMessage() ||
                    'Failed to submit form',
                })
                this.formScriptContext.form.resetAbort()
                this.processing = false
              } else {
                updatedData = { ...formData, ...overridedFieldValue }
                const updatedDataDiff = this.unTouchedResource
                  ? getChangedProperties(this.unTouchedResource, updatedData)
                  : updatedData
                this.submitFn(updatedDataDiff).finally((s) => {
                  this.processing = false
                })
              }
            })
          }
        }
        updatedData = { ...formData, ...overridedFieldValue }
      }
      const updatedDataDiff = this.unTouchedResource
        ? getChangedProperties(this.unTouchedResource, updatedData)
        : updatedData
      this.submitFn(updatedDataDiff).finally((s) => {
        this.processing = false
      })
    },
  },
}
</script>
