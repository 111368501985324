<template>
  <tr
    :key="item.id"
    :data-index="index"
    :class="{
      'active-cursor': index === currentCursorPosition,
      'cursor-pointer': view === 'split',
      'active-item':
        selectedItemIds.indexOf(item.id) >= 0 ||
        (activeItem || {}).id === item.id,
    }"
    @click="onRowClick(item)"
  >
    <td v-if="selectable" class="checkbox" style="z-index: 0">
      <MCheckbox
        :checked="selectedItemIds.indexOf(item.id) >= 0"
        @click.stop="(e) => e"
        @change="toggleSelectItem(item)"
      />
    </td>
    <td
      v-for="(column, columnIndex) in columns"
      :key="column.key"
      :class="column.class"
      :align="column.align"
      :style="
        view !== 'split' && columnWidths[columnIndex]
          ? { width: `${columnWidths[columnIndex]}px` }
          : {}
      "
    >
      <ResourceListTitle
        v-if="column.key === 'subject'"
        :name="item.name"
        :subject="item.subject"
        v-bind="
          view === 'split'
            ? {
                requesterName: item.requesterData.name,
                requesterEmail: item.requesterData.email,
                createdAt: item.createdAt,
              }
            : {}
        "
        :external-link="view === 'list'"
        :internal-link="view === 'list'"
        :link="
          item.archived === false
            ? $modules.getModuleRoute('ticket', 'view', {
                params: {
                  id: item.id,
                  serviceCatalogId: item.serviceCatalogId,
                  ticketType: moduleName,
                },
              })
            : undefined
        "
      />
      <template v-else-if="column.key === 'requesterId'">
        <FlotoUserDrawer :user="item.requesterData" />
      </template>
      <template v-else-if="column.key === 'createdTime'">
        <div class="page-secondary-text-color text-ellipsis">
          {{ item.createdAt | datetime }}
        </div>
      </template>
      <template v-else-if="column.key === 'technicianId'">
        <FlotoTechnicianPicker
          :active-technicians="activeTechniciansContext.activeTechnicians"
          :value="item.technicianId"
          :module-name="moduleName"
          :group-id="item.groupId"
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          :mandatory-selection="isRequiredFn('technicianId')"
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              technicianId: $event,
            })
          "
        />
      </template>
      <template v-else-if="column.key === 'statusId'">
        <FlotoStatusPicker
          :value="item.statusId"
          :module-name="moduleName"
          :disabled="
            disabled ||
            item.statusId === closedStatusId ||
            item.archived ||
            moduleName === $constants.CHANGE ||
            moduleName === $constants.RELEASE
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              statusId: $event,
              hasSecondaryTickets: item.hasSecondaryTickets,
            })
          "
        />
      </template>
      <template v-else-if="column.key === 'priorityId'">
        <FlotoPriorityPicker
          :value="item.priorityId"
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              priorityId: $event,
            })
          "
        />
      </template>
      <template v-else-if="column.key === 'dueBy'">
        <template v-if="item.dueBy < 0">
          {{ item.oldDueBy | datetime }}
        </template>
        <template v-else-if="item.dueBy">
          {{ item.dueBy | datetime }}
        </template>
        <template v-else> --- </template>
      </template>
      <template v-else-if="column.key === 'responseDue'">
        <span v-if="item.responseDue >= 0">
          {{ item.responseDue | datetime }}
        </span>
        <span v-else>{{ item.oldResponseDue | datetime }}</span>
      </template>
      <template v-else-if="column.key === 'affectedServices'">
        <FlotoBusinessServicePicker
          :value="item.affectedServices"
          placeholder="---"
          as-input
          multiple
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              affectedServices: $event || null,
            })
          "
        />
      </template>
      <template v-else-if="column.key === 'resolutionDueLevel'">
        <FlotoDueDatePicker
          :value="item.dueBy"
          :show-icon="false"
          disabled
          :module-name="moduleName"
          :status-id="item.statusId"
        />
      </template>
      <template v-else-if="column.key === 'groupId'">
        <div class="flex items-center">
          <FlotoTechnicianGroupPicker
            :value="item.groupId"
            :as-input="false"
            :suggested-value="(suggestedValues || {}).suggestedGroupId"
            :disabled="
              disabled || item.statusId === closedStatusId || item.archived
            "
            :mandatory-selection="isRequiredFn('groupId')"
            @change="
              update({
                id: item.id,
                serviceCatalogId: item.serviceCatalogId,
                groupId: $event,
              })
            "
          />
        </div>
      </template>
      <template v-else-if="column.key === 'urgencyId'">
        <FlotoUrgencyPicker
          :value="item.urgencyId"
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              urgencyId: $event,
            })
          "
        />
      </template>
      <template v-else-if="column.key === 'createdById'">
        <FlotoUserDrawer :user-id="item.createdBy" />
      </template>
      <template v-else-if="column.key === 'impactId'">
        <FlotoImpactPicker
          :value="item.impactId"
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              impactId: $event,
            })
          "
        />
      </template>
      <template v-else-if="column.key === 'categoryId'">
        <div class="flex items-center">
          <FlotoCategoryPicker
            :value="item.categoryId"
            :module-name="moduleName"
            placeholder="---"
            :as-input="false"
            :disabled="
              disabled || item.statusId === closedStatusId || item.archived
            "
            :suggested-value="(suggestedValues || {}).suggestedCategoryId"
            @change="
              update({
                id: item.id,
                serviceCatalogId: item.serviceCatalogId,
                categoryId: $event,
              })
            "
          />
        </div>
      </template>
      <template v-else-if="column.key === 'departmentId'">
        <div class="flex items-center">
          <FlotoDepartmentPicker
            :value="item.departmentId"
            :as-input="false"
            placeholder="---"
            :module-name="moduleName"
            :disabled="
              disabled || item.statusId === closedStatusId || item.archived
            "
            @change="
              update({
                id: item.id,
                serviceCatalogId: item.serviceCatalogId,
                departmentId: $event,
              })
            "
          />
        </div>
      </template>
      <template v-else-if="column.key === 'companyId'">
        <CompanyPicker
          :value="item.companyId"
          :module-name="moduleName"
          :as-input="false"
          placeholder="---"
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              companyId: $event,
            })
          "
        />
      </template>
      <template v-else-if="column.key === 'sourceId'">
        <FlotoSourcePicker
          :value="item.sourceId"
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              sourceId: $event,
            })
          "
        />
      </template>
      <template v-else-if="column.key === 'locationId'">
        <div class="flex items-center">
          <FlotoLocationPicker
            :value="item.locationId"
            placeholder="---"
            :as-input="false"
            :module-name="moduleName"
            :disabled="
              disabled || item.statusId === closedStatusId || item.archived
            "
            @change="
              update({
                id: item.id,
                serviceCatalogId: item.serviceCatalogId,
                locationId: $event,
              })
            "
          />
        </div>
      </template>
      <template v-else-if="column.key === 'supportLevel'">
        <FlotoSupportLevelPicker
          v-if="item.supportLevel"
          :value="item.supportLevel"
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              supportLevel: $event,
            })
          "
        />
        <span v-else>---</span>
      </template>
      <template v-else-if="column.key === 'natureOfProblem'">
        <FlotoDropdownPicker
          :value="item.natureOfProblem"
          :options="[
            { text: $t('proactive'), key: 'proactive' },
            { text: $t('reactive'), key: 'reactive' },
          ]"
          as-input
          placeholder="---"
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              natureOfProblem: $event || null,
            })
          "
        />
      </template>
      <template v-else-if="column.key === 'knownError'">
        {{ $t(item.knownError === 'true' ? 'yes' : 'no') }}
      </template>
      <template v-else-if="column.key === 'business-service'">
        <FlotoBusinessServicePicker
          :value="item.businessServiceId"
          placeholder="---"
          as-input
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              businessServiceId: $event || null,
            })
          "
        />
      </template>
      <template
        v-else-if="
          column.key === 'riskTypeId' && moduleName === $constants.CHANGE
        "
      >
        <FlotoRiskTypePicker
          :value="item.riskTypeId"
          placeholder="---"
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              riskTypeId: $event || null,
            })
          "
        />
      </template>
      <template
        v-else-if="
          column.key === 'riskTypeId' && moduleName === $constants.RELEASE
        "
      >
        <ReleaseRiskTypePicker
          :value="item.riskTypeId"
          placeholder="---"
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              riskTypeId: $event || null,
            })
          "
        />
      </template>
      <template v-else-if="column.key === 'changeTypeId'">
        <FlotoChangeTypePicker
          :value="item.changeTypeId"
          placeholder="---"
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              changeTypeId: $event || null,
            })
          "
        />
      </template>
      <template v-else-if="column.key === 'releaseTypeId'">
        <ReleaseTypePicker
          :value="item.releaseTypeId"
          placeholder="---"
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              releaseTypeId: $event || null,
            })
          "
        />
      </template>
      <template
        v-else-if="
          column.key === 'reasonType' && moduleName === $constants.CHANGE
        "
      >
        <FlotoReasonTypePicker
          :value="item.reasonType"
          placeholder="---"
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              reasonType: $event || null,
            })
          "
        />
      </template>
      <template
        v-else-if="
          column.key === 'reasonType' && moduleName === $constants.RELEASE
        "
      >
        <ReleaseReasonTypePicker
          :value="item.reasonType"
          placeholder="---"
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              reasonType: $event || null,
            })
          "
        />
      </template>
      <template v-else-if="column.key === 'changeImplementor'">
        <FlotoTechnicianPicker
          :value="item.changeImplementor"
          :module-name="moduleName"
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              changeImplementor: $event,
            })
          "
        />
      </template>
      <template v-else-if="column.key === 'releaseEngineer'">
        <FlotoTechnicianPicker
          :value="item.releaseEngineer"
          :module-name="moduleName"
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              releaseEngineer: $event,
            })
          "
        />
      </template>
      <template v-else-if="column.key === 'changeManager'">
        <FlotoTechnicianPicker
          :value="item.changeManager"
          :module-name="moduleName"
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              changeManager: $event,
            })
          "
        />
      </template>
      <template v-else-if="column.key === 'releaseManager'">
        <FlotoTechnicianPicker
          :value="item.releaseManager"
          :module-name="moduleName"
          :disabled="item.statusId === closedStatusId || item.archived"
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              releaseManager: $event,
            })
          "
        />
      </template>
      <template v-else-if="column.key === 'changeReviewer'">
        <FlotoTechnicianPicker
          :value="item.changeReviewer"
          :module-name="moduleName"
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              changeReviewer: $event,
            })
          "
        />
      </template>
      <template v-else-if="column.key === 'releaseReviewer'">
        <FlotoTechnicianPicker
          :value="item.releaseReviewer"
          :module-name="moduleName"
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              releaseReviewer: $event,
            })
          "
        />
      </template>
      <template v-else-if="column.key === 'qaManager'">
        <FlotoTechnicianPicker
          :value="item.qaManager"
          :module-name="moduleName"
          :disabled="
            disabled || item.statusId === closedStatusId || item.archived
          "
          @change="
            update({
              id: item.id,
              serviceCatalogId: item.serviceCatalogId,
              qaManager: $event,
            })
          "
        />
      </template>
      <template v-else-if="column.key === 'updatedTime'">
        {{ item.updatedAt | datetime }}
      </template>
      <template
        v-else-if="
          column.key === 'startDate' && moduleName === $constants.RELEASE
        "
      >
        {{ item.startDate | datetime }}
      </template>
      <template
        v-else-if="
          column.key === 'endDate' && moduleName === $constants.RELEASE
        "
      >
        {{ item.endDate | datetime }}
      </template>
      <template v-else-if="column.key === 'lastResolvedTime'">
        {{ item.lastResolvedTime | datetime }}
      </template>
      <template v-else-if="column.key === 'lastClosedTime'">
        {{ !item.lastClosedTime ? '---' : item.lastClosedTime | datetime }}
      </template>
      <template v-else-if="column.key === 'lastApprovedDate'">
        {{ !item.lastApprovedDate ? '---' : item.lastApprovedDate | datetime }}
      </template>
      <template v-else-if="column.key === 'ticketAge'">
        <FlotoDueDatePicker
          v-if="item.ticketAge"
          :value="item.ticketAge"
          disabled
          :module-name="moduleName"
          is-show-age
          icon-size="2x"
        />
        <span v-else>---</span>
      </template>
      <template v-else-if="column.key === 'approvalStatus'">
        <span v-if="item.approvalStatus">{{ $tc(item.approvalStatus) }}</span>
        <span v-else>---</span>
      </template>
      <template v-else-if="column.key === 'closedBy'">
        <FlotoUserDrawer v-if="item.closedBy" :user-id="item.closedBy" />
        <span v-else>---</span>
      </template>
      <template v-else-if="column.key === 'resolvedBy'">
        <FlotoUserDrawer v-if="item.resolvedBy" :user-id="item.resolvedBy" />
        <span v-else>---</span>
      </template>
      <template v-else-if="column.key === 'tags'">
        {{ (item.tags || []).length ? item.tags.join(', ') : '---' }}
      </template>
      <template v-else-if="/^\d+$/.test(column.key)">
        <ListTableCustomFieldValue
          :module-name="moduleName"
          :column="column"
          :item="item"
        />
      </template>
      <template v-else> Column Slot Missing! </template>
      <!-- custom field slots -->
    </td>
  </tr>
</template>

<script>
import ReleaseReasonTypePicker from '@components/data-picker/release-reason-type-picker'
import ReleaseRiskTypePicker from '@components/data-picker/release-risk-type-picker'
import ReleaseTypePicker from '@components/data-picker/release-type-picker'
import ResourceListTitle from '@components/resource/resource-list-title'
import CompanyPicker from '@components/data-picker/company-picker'
import ListTableCustomFieldValue from '@components/table/list-table-custom-field-value.vue'

export default {
  name: 'TableListRow',
  components: {
    ResourceListTitle,
    ReleaseTypePicker,
    ReleaseRiskTypePicker,
    ReleaseReasonTypePicker,
    CompanyPicker,
    ListTableCustomFieldValue,
  },
  inject: ['activeTechniciansContext', 'suggestedFieldValuesContext'],
  inheritAttrs: false,
  props: {
    index: { type: Number, required: true },
    columnWidths: { type: Array, required: true },
    moduleName: { type: String, required: true },
    closedStatusId: { type: Number, required: true },
    item: { type: Object, required: true },
    currentCursorPosition: { type: Number, required: true },
    selectedItemIds: { type: Array, required: true },
    activeItem: { type: Object, default: undefined },
    onRowClick: { type: Function, required: true },
    toggleSelectItem: { type: Function, required: true },
    update: { type: Function, required: true },
    view: { type: String, default: 'list' },
    columns: { type: Array, required: true },
    // eslint-disable-next-line
    selectable: { type: Boolean, required: true },
    isRequiredFn: {
      type: Function,
      default: (e) => e,
    },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    suggestedValues() {
      let value
      const fieldValue =
        (
          (this.suggestedFieldValuesContext || {}).suggestedFieldValues || []
        ).find((i) => i.refId === this.item.id) || {}
      if (
        (fieldValue || {}).suggestedCategoryId ||
        (fieldValue || {}).suggestedGroupId
      ) {
        value = fieldValue
      }
      return value
    },
  },
}
</script>
