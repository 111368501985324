<template>
  <FlotoModule>
    <FormRulesProvider
      :key="$route.params.ticketType"
      :module-name="$route.params.ticketType"
    >
      <RouterView :key="$route.fullPath" />
    </FormRulesProvider>
  </FlotoModule>
</template>

<script>
import Constants from '@constants'
import FormRulesProvider from '@components/providers/form-rules-provider/form-rules-provider'

import { PageMethods } from '@state/modules/page'
import {
  BusinessServiceMethods,
  BusinessServiceComputed,
} from '@state/modules/business-service'
import { RiskTypeMethods, RiskTypeComputed } from '@state/modules/risk-type'
import {
  ReleaseRiskTypeComputed,
  ReleaseRiskTypeMethods,
} from '@state/modules/release-risk-type'
import {
  ChangeTypeMethods,
  ChangeTypeComputed,
} from '@state/modules/change-type'
import {
  ReleaseTypeComputed,
  ReleaseTypeMethods,
} from '@state/modules/release-type'
import {
  ReasonTypeMethods,
  ReasonTypeComputed,
} from '@state/modules/reason-type'
import {
  ReleaseReasonTypeMethods,
  ReleaseReasonTypeComputed,
} from '@state/modules/release-reason-type'
import { CustomRulesMethods } from '@state/modules/custom-rules'
import { LicenseComputed } from '@state/modules/license'
import { PreferenceComputed } from '@state/modules/preference'
import { FieldMappingMethods } from '@state/modules/field-mapping'

export default {
  name: 'RequestModule',
  components: { FormRulesProvider },
  page() {
    return {
      title: this.$tc(this.$route.params.ticketType),
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.name.indexOf('feedback') >= 0) {
      return next()
    }
    if (
      [
        Constants.REQUEST,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
      ].indexOf(to.params.ticketType) === -1
    ) {
      return next({ name: '404' })
    }
    // vm is this
    next((vm) => {
      if (vm.availableModulesInLicense.indexOf(to.params.ticketType) === -1) {
        return vm.$router.replace({ name: 'upgrade-plan' })
      }
      if (vm.myAllowedModules.indexOf(to.params.ticketType) === -1) {
        return vm.$router.replace({ name: '404' })
      }
      if (vm[`${to.params.ticketType}FetchNoteRules`]) {
        vm[`${to.params.ticketType}FetchNoteRules`]()
      }
      if (to.params.ticketType === Constants.REQUEST) {
        if (vm.requestFieldMappingFetch) {
          vm.requestFieldMappingFetch()
        }
        if (
          vm.service_catalogFetchNoteRules &&
          vm.service_catalogFetchDialogRules
        ) {
          vm.service_catalogFetchNoteRules()
          vm.service_catalogFetchDialogRules()
        }
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    setTimeout(() => {
      if (this[`${from.params.ticketType}PageReset`]) {
        this[`${from.params.ticketType}PageReset`]()
      }
    }, 400)
    next()
  },
  computed: {
    ...LicenseComputed,
    ...BusinessServiceComputed,
    ...RiskTypeComputed,
    ...ChangeTypeComputed,
    ...ReasonTypeComputed,
    ...ReleaseRiskTypeComputed,
    ...ReleaseTypeComputed,
    ...ReleaseReasonTypeComputed,
    ...PreferenceComputed,
  },
  watch: {
    '$route.params.ticketType': function (newValue, prevValue) {
      if (this.availableModulesInLicense.indexOf(newValue) === -1) {
        return
      }
      if (this[`${prevValue}PageReset`]) {
        this[`${prevValue}PageReset`]()
      }
      if (this[`${newValue}FetchNoteRules`]) {
        this[`${newValue}FetchNoteRules`]()
      }
      if (
        newValue === this.$constants.REQUEST &&
        this.service_catalogFetchNoteRules &&
        this.service_catalogFetchDialogRules
      ) {
        this.service_catalogFetchNoteRules()
        this.service_catalogFetchDialogRules()
      }
      if (newValue === this.$constants.PROBLEM) {
        if (!this.isBusinessServiceLoaded) {
          this.fetchBusinessServices()
        }
      }
      if (newValue === this.$constants.CHANGE) {
        if (!this.isRiskTypeLoaded) {
          this.fetchRiskTypes()
        }
        if (!this.isChangeTypeLoaded) {
          this.fetchChangeTypes()
        }
        if (!this.isReasonTypeLoaded) {
          this.fetchReasonTypes()
        }
      }
      if (newValue === this.$constants.RELEASE) {
        if (!this.isReleaseRiskTypeLoaded) {
          this.fetchReleaseRiskTypes()
        }
        if (!this.isReleaseTypeLoaded) {
          this.fetchReleaseTypes()
        }
        if (!this.isReleaseReasonTypeLoaded) {
          this.fetchReleaseReasonTypes()
        }
      }
    },
  },
  methods: {
    ...PageMethods,
    ...CustomRulesMethods,
    ...BusinessServiceMethods,
    ...RiskTypeMethods,
    ...ChangeTypeMethods,
    ...ReasonTypeMethods,
    ...ReleaseRiskTypeMethods,
    ...ReleaseTypeMethods,
    ...ReleaseReasonTypeMethods,
    ...FieldMappingMethods,
  },
}
</script>
